.ceramicsContent{
    margin: 0px 70px 35px 28%;
    flex-direction: column;
}   
.main-layout{
    padding: 100px 50px 0 50px;
    width: 100%;
}
.highlight-link{
    font-weight: 900;
}
img{
    cursor: pointer;
    margin: 0 auto;
    display: block;
    padding: 20px 0;
}
.mobile-title{
    text-align: center;
    display: none;
    letter-spacing: 2px;
}
.home-description{
    padding-top: 8%;
}
/*X-Small devices (landscape phones, 576px and up)*/
@media (max-width: 575.98px) {
    .ceramicsContent{
        margin: 100px 0 0 0;
        padding:0;
        .col-12{
            padding: 0;
        }
    }  
    .main-layout{
        padding: 0;
        width: 100%;
    }
    .row{
        margin: 0;
    }
    .mobile-title{
        display: block;
    }
    .home-description{
        padding-top: 8%;
        text-align: center;
        font-size: 0.85rem;
    }
}
/* Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) and (max-width: 767.98px) {
    .main-layout{
        padding: 0;
        width: 100%;
    }
    .ceramicsContent{
        margin: 0px 70px 35px 210px;
        padding:0;
        .col-12{
            padding: 0;
        }
    }  
    .row{
        margin: 0;
    }

}

/* Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) and (max-width: 991.98px)  {
    .main-layout{
        padding: 0;
        width: 100%;
    }
    .ceramicsContent{
        margin: 0px 70px 35px 250px;
        padding:0;
        .col-12{
            padding: 0;
        }
    }  
    .row{
        margin: 0;
    }
}
/* Portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {

}

/* Large devices (desktops, 992px and up)*/
@media (min-width: 992px) and (max-width: 1199.98px) { 

}

/* Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {

}