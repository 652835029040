body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-family: 'Open Sans', sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.h-75{
  height: 75%;
}

.w-75{
  width: 75%;
}
.w-60{
  width:60%;
}
/* X-Small devices (landscape phones, 576px and up)*/
@media (max-width: 575.98px) {
  .w-60{
    width:80%;
  }
}
/* Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) and (max-width: 767.98px) {
  .w-60{
    width: 65%;
  }
}

/* Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) and (max-width: 991.98px)  {
  .w-60{
    width: 55%;
  }
}
/* Portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  .w-60{
    width: 90%;
  }
  .w-100{
    width: 100%;
  }
}
/* Large devices (desktops, 992px and up)*/
@media (min-width: 992px) and (max-width: 1199.98px) { 

}
